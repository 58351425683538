import React from 'react';
import ReactDOM from 'react-dom/client';
import { PrivyProvider } from '@privy-io/react-auth';

const loginRoot = document.getElementById('loginPrivy');
const logoutRoot = document.getElementById('logoutPrivy');

const renderComponent = async (elementId, importPromise) => {
  const root = ReactDOM.createRoot(document.getElementById(elementId));
  const Component = (await importPromise).default;
  
  root.render(
    <React.StrictMode>
      <PrivyProvider
        appId="clxemmxy905w5101wwy4ahs1m"
        config={{
          loginMethods: ['email','sms', 'wallet', 'google', 'apple', 'farcaster','twitter','discord'],
          appearance: {
            theme: 'light',
            accentColor: '#30BCC2',
            logo: 'https://dapps.social/logo.png',
          },
        }}
      >
        <Component />
      </PrivyProvider>
    </React.StrictMode>
  );
};

if (loginRoot) {
  renderComponent('loginPrivy', import('./components/Login/Login'));
} else if (logoutRoot) {
  renderComponent('logoutPrivy', import('./components/Logout/Logout'));
}
